import React, { useMemo } from "react";
import {
  Dropdown,
  Form,
  Header,
  Input,
  Label,
  Menu,
  MenuItem,
  Segment,
  Statistic,
  StatisticLabel,
  StatisticValue,
} from "semantic-ui-react";
import { NotifyTopNav } from "../../../components/NotifyTopNav";
import { useNotifySubscriptionGroups } from "../../../hooks/useNotifySubscriptionGroups";
import {
  NotifySubscribersInfiniteTable,
  NotifySubscribersInfiniteTableType,
  NotifySubscribersInfiniteTableView,
} from "./NotifySubscribersInfiniteTable";
import { useQueryState, parseAsString, parseAsArrayOf, parseAsStringEnum, parseAsInteger } from "nuqs";

import classNames from "classnames";
import PlaceholderUntilLoaded from "../../../../../components/global_components/PlaceholderUntilLoaded";
import { useInfiniteNotifySubscriberContactMethods } from "../../../hooks/useInfiniteNotifySubscriberContactMethods";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { nuqsSortParser } from "../../../util/nuqsSortParser";

export function NotifySubscribersPage() {
  const [filterStatus, setFilterStatus] = useQueryState<NotifySubscribersInfiniteTableView>(
    "status",
    parseAsStringEnum(["Enrolled", "Invited", "OptedOut"]).withDefault("Enrolled").withOptions({
      history: "push",
    }),
  );
  const [filterQuery, setFilterQuery] = useQueryState("query", parseAsString.withDefault(""));
  const debouncedFilterQuery = useDebounce(filterQuery, 350);
  const [filterSubscriptionGroups, setFilterSubscriptionGroups] = useQueryState(
    "groupIds",
    parseAsArrayOf(parseAsInteger).withDefault([]),
  );
  const [filterTypes, setFilterTypes] = useQueryState(
    "types",
    parseAsArrayOf<NotifySubscribersInfiniteTableType>(parseAsStringEnum(["Phone", "Email", "App"])).withDefault([]),
  );
  const [sortBy] = useQueryState("sort", nuqsSortParser); // sortBy is set in NotifySubscribersInfiniteTable

  const { subscriptionGroups, isSubscriptionGroupsLoading } = useNotifySubscriptionGroups({});
  const {
    subscriberContactMethodsTypeCounts,
    subscriberContactMethodsTypeTotalCount,
    isSubscriberContactMethodsLoading,
  } = useInfiniteNotifySubscriberContactMethods({
    take: 100,
    query: debouncedFilterQuery,
    status: filterStatus,
    subscriptionGroupIds: filterSubscriptionGroups,
    types: filterTypes,
    sort: sortBy?.map(({ id, desc }) => ({ column: id, direction: desc ? "desc" : "asc" })),
  });

  const filters = useMemo(
    () => ({
      status: filterStatus,
      query: debouncedFilterQuery,
      subscriptionGroups: filterSubscriptionGroups,
      types: filterTypes,
    }),
    [filterStatus, debouncedFilterQuery, filterSubscriptionGroups, filterTypes],
  );

  return (
    <div>
      <NotifyTopNav />
      <div className='tw-p-3 sm:tw-p-6'>
        <Segment className='tw-flex tw-flex-col tw-gap-3 tw-p-6 tw-transition-all'>
          <div className='tw-flex tw-flex-col tw-justify-between tw-gap-3 md:tw-flex-row md:tw-items-center'>
            <div className='tw-flex tw-flex-col tw-gap-3 sm:tw-flex-row sm:tw-items-center sm:tw-gap-9'>
              <Header className='tw-mb-0' size='large'>
                Subscribers
              </Header>
              <div>
                <Menu compact className='tw-min-h-0 tw-shadow-none'>
                  <MenuItem
                    link
                    className='tw-px-4 tw-py-3'
                    active={filterStatus === "Enrolled"}
                    onClick={() => setFilterStatus("Enrolled")}
                  >
                    Enrolled
                  </MenuItem>
                  <MenuItem
                    link
                    className='tw-px-4 tw-py-3'
                    active={filterStatus === "Invited"}
                    onClick={() => setFilterStatus("Invited")}
                  >
                    Invited
                  </MenuItem>
                  <MenuItem
                    link
                    className='tw-px-4 tw-py-3'
                    active={filterStatus === "OptedOut"}
                    onClick={() => setFilterStatus("OptedOut")}
                  >
                    Opted-Out
                  </MenuItem>
                </Menu>
              </div>
            </div>
            <PlaceholderUntilLoaded
              className='-tw-mt-2 tw-w-64'
              isLoading={isSubscriberContactMethodsLoading}
              lineCount={2}
            >
              <div className='tw-flex tw-items-center tw-gap-6'>
                <Statistic size='mini' className='tw-m-0'>
                  <StatisticLabel className='tw-text-xs tw-font-semibold tw-text-grey'>Phone</StatisticLabel>
                  <StatisticValue>{subscriberContactMethodsTypeCounts?.Phone?.toLocaleString() ?? 0}</StatisticValue>
                </Statistic>
                <Statistic size='mini' className='tw-m-0'>
                  <StatisticLabel className='tw-text-xs tw-font-semibold tw-text-grey'>Email</StatisticLabel>
                  <StatisticValue>{subscriberContactMethodsTypeCounts?.Email?.toLocaleString() ?? 0}</StatisticValue>
                </Statistic>
                <Statistic size='mini' className='tw-m-0'>
                  <StatisticLabel className='tw-text-xs tw-font-semibold tw-text-grey'>Apps</StatisticLabel>
                  <StatisticValue>{subscriberContactMethodsTypeCounts?.App?.toLocaleString() ?? 0}</StatisticValue>
                </Statistic>
                <Statistic size='mini' className='tw-m-0'>
                  <StatisticLabel className='tw-text-xs tw-font-semibold tw-text-grey'>Total</StatisticLabel>
                  <StatisticValue>{subscriberContactMethodsTypeTotalCount?.toLocaleString() ?? 0}</StatisticValue>
                </Statistic>
              </div>
            </PlaceholderUntilLoaded>
          </div>
          <div className='tw-flex tw-flex-col tw-gap-3 md:tw-flex-row'>
            <Form.Field className='tw-m-0'>
              <Input
                className='tw-w-full md:tw-w-64'
                placeholder='Search Subscribers'
                icon={"search"}
                value={filterQuery}
                onChange={(e, { value }) => setFilterQuery(value, { throttleMs: 500 })}
              />
            </Form.Field>
            <Form.Field className='tw-m-0'>
              <Dropdown
                className={classNames("tw-transition-none", {
                  "md:tw-w-64": filterSubscriptionGroups.length <= 0,
                  "md:tw-w-80": filterSubscriptionGroups.length > 0,
                })}
                options={
                  subscriptionGroups?.map((group) => ({
                    key: group.id,
                    value: group.id,
                    text: group.name,
                  })) ?? []
                }
                placeholder='Subscription Group'
                clearable
                loading={isSubscriptionGroupsLoading}
                selectOnBlur={false}
                multiple
                search
                fluid
                selection
                value={filterSubscriptionGroups}
                onChange={(_, { value }) => setFilterSubscriptionGroups(value as number[])}
                renderLabel={(label) => {
                  return <Label className='tw-whitespace-nowrap' content={label.text} />;
                }}
              />
            </Form.Field>
            <Form.Field className='tw-m-0'>
              <Dropdown
                className={classNames("tw-whitespace-nowrap tw-transition-none", {
                  "md:tw-w-28": filterTypes.length <= 0,
                  "md:tw-w-80": filterTypes.length > 0,
                })}
                options={[
                  { key: "Phone", value: "Phone", text: "Phone" },
                  { key: "Email", value: "Email", text: "Email" },
                  { key: "App", value: "App", text: "App" },
                ]}
                placeholder='Type'
                clearable
                selectOnBlur={false}
                multiple
                search
                fluid
                selection
                value={filterTypes}
                onChange={(_, { value }) => setFilterTypes(value as NotifySubscribersInfiniteTableType[])}
                renderLabel={(label) => {
                  return <Label className='tw-whitespace-nowrap' content={label.text} />;
                }}
              />
            </Form.Field>
          </div>
        </Segment>
        <div className='tw-h-[calc(100vh-503px)] sm:tw-h-[calc(100vh-324px)] md:tw-h-[calc(100vh-276px)]'>
          <NotifySubscribersInfiniteTable filters={filters} />
        </div>
      </div>
    </div>
  );
}
