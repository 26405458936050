import React, { useEffect, useMemo } from "react";
import { faEnvelope, faMobile, faPhone, faPhoneVolume } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { formatDistanceToNow } from "date-fns";
import { Column as ReactTableColumn, useColumnOrder, useSortBy, useTable } from "react-table";
import { AutoSizer, Column, InfiniteLoader, Table } from "react-virtualized";
import { Checkbox, Icon, Loader, Popup, Segment } from "semantic-ui-react";
import { ZonedDateTime } from "../../../../../components/global_components/ZonedDateTime";
import { pluralize } from "../../../../../utils/pluralize";
import { useQueryState } from "nuqs";
import { ParsedPhoneNumber } from "../../../../../components/global_components/form_components/ControlledPhoneNumber";
import ContactConnectedProfilePopup from "../../../../contacts/components/ContactConnectedProfilePopup";
import { ContactAvatar } from "../../../../permits/components/ContactAvatar";
import { useBulkSelect } from "../../../hooks/useBulkSelect";
import { useInfiniteNotifySubscriberContactMethods } from "../../../hooks/useInfiniteNotifySubscriberContactMethods";
import { nuqsSortParser } from "../../../util/nuqsSortParser";

import "./NotifySubscribersInfiniteTable.scss";
import OverflowTextTooltip from "../../../../../components/global_components/OverflowTextTooltip";

export type NotifySubscribersInfiniteTableType = "Phone" | "Email" | "App";
export type NotifySubscribersInfiniteTableView = "Enrolled" | "Invited" | "OptedOut";

export function NotifySubscribersInfiniteTable({
  filters,
}: {
  filters: {
    status: NotifySubscribersInfiniteTableView;
    query: string;
    subscriptionGroups: number[];
    types: NotifySubscribersInfiniteTableType[];
  };
}) {
  const [sortBy, setSortBy] = useQueryState("sort", nuqsSortParser.withDefault([{ id: "createdAt", desc: true }]));

  const {
    bulkSelections,
    onBulkSelectAll,
    onBulkSelect,
    shouldBulkSelectBeChecked,
    shouldBulkSelectAllBeChecked,
    shouldBulkSelectAllBeIndeterminate,
  } = useBulkSelect();

  const toggleSortBy = (columnId: string, clearPrevious = false) => {
    setSortBy((prev) => {
      const existingSort = prev.find((sort) => sort.id === columnId);

      if (clearPrevious) {
        if (existingSort) return [{ id: columnId, desc: !existingSort.desc }];
        return [{ id: columnId, desc: true }];
      }

      if (existingSort) return prev.map((sort) => (sort.id === columnId ? { ...sort, desc: !sort.desc } : sort));
      return [...prev, { id: columnId, desc: true }];
    });
  };

  const {
    subscriberContactMethods,
    subscriberContactMethodsTotal,
    fetchMoreSubscriberContactMethods,
    isSubscriberContactMethodsFetching,
    isSubscriberContactMethodsLoading,
  } = useInfiniteNotifySubscriberContactMethods({
    take: 100,
    query: filters.query,
    status: filters.status,
    subscriptionGroupIds: filters.subscriptionGroups,
    types: filters.types,
    sort: sortBy.map(({ id, desc }) => ({ column: id, direction: desc ? "desc" : "asc" })),
  });

  // useMemo is required here
  const columns = useMemo<ReactTableColumn<(typeof subscriberContactMethods)[0]>[]>(
    () => [
      {
        accessor: "id",
        Header: ({}) => (
          <Checkbox
            indeterminate={shouldBulkSelectAllBeIndeterminate()}
            checked={shouldBulkSelectAllBeChecked()}
            onChange={onBulkSelectAll}
          />
        ),
        Cell: ({ value }) => (
          <Checkbox checked={shouldBulkSelectBeChecked(value)} onChange={() => onBulkSelect(value)} />
        ),
        disableSortBy: true,
        width: 45,
      },
      {
        accessor: "type",
        Header: "Type",
        Cell: ({ value }) => {
          const iconLookup: Record<string, Function> = {
            Email: () => <FontAwesomeIcon className='tw-text-purple' icon={faEnvelope} />,
            Phone: () => <FontAwesomeIcon className='tw-text-orange' icon={faPhone} />,
            App: () => <FontAwesomeIcon className='tw-text-teal' icon={faMobile} />,
          };
          return (
            <div className='tw-space-x-3'>
              {iconLookup[value]()}
              <span>{value}</span>
            </div>
          );
        },
        width: 100,
      },
      {
        accessor: "identifier",
        Header: "Phone/Email/App",
        Cell: ({ value, row }) => {
          if (row.original.type === "Phone")
            return (
              <span className='tw-flex tw-items-center tw-gap-2'>
                <ParsedPhoneNumber value={value} />
                {row.original.isPhoneNumberSMSCapable === false && (
                  <Popup
                    content='This number cannot receive SMS messages'
                    trigger={<FontAwesomeIcon icon={faPhoneVolume} />}
                    inverted
                    position='top left'
                  />
                )}
              </span>
            );
          return <div>{value}</div>;
        },
        minWidth: 200,
      },
      {
        accessor: "person",
        Header: "Contact Match",
        Cell: ({ value, row }) => {
          if (value) {
            return (
              <ContactConnectedProfilePopup
                personId={value.personId}
                trigger={
                  <div className='tw-flex tw-items-center tw-gap-3'>
                    <ContactAvatar firstName={value.firstName ?? ""} lastName={value.lastName ?? ""} />
                    <div>
                      {value.firstName} {value.lastName}
                    </div>
                  </div>
                }
              />
            );
          }
          const potentialMatchNames = row.original.potentialPersonMatches.map((match) =>
            [match.firstName, match.lastName].join(" "),
          );
          if (potentialMatchNames.length) {
            return <OverflowTextTooltip className='tw-whitespace-nowrap' text={potentialMatchNames.join(", ")} />;
          }

          return null;
        },
      },
      {
        accessor: "origin",
        Header: "Origin",
        Cell: ({ value }) => <div>{value}</div>,
        width: 120,
      },
      {
        accessor: "invitedAt",
        Header: "Invited",
        Cell: ({ value }) => (
          <Popup
            content={<ZonedDateTime value={value} />}
            trigger={!!value ? <div>{formatDistanceToNow(value)} ago</div> : null}
            inverted
            position='top left'
          ></Popup>
        ),
        minWidth: 100,
      },
      {
        accessor: "invitedMethod",
        Header: "Method",
        Cell: ({ value }) => <div>{value}</div>,
        width: 100,
      },
      {
        accessor: "createdAt",
        Header: "Joined",
        Cell: ({ value }) => (
          <Popup
            content={<ZonedDateTime value={value} />}
            trigger={<div>{formatDistanceToNow(value)} ago</div>}
            inverted
            position='top left'
          ></Popup>
        ),
        minWidth: 120,
      },
      {
        accessor: "optedOutAt",
        Header: "Opted-Out",
        Cell: ({ value }) => (
          <Popup
            content={<ZonedDateTime value={value} />}
            trigger={value ? <div>{formatDistanceToNow(value)} ago</div> : null}
            inverted
            position='top left'
          ></Popup>
        ),
      },
      {
        accessor: "optedOutMethod",
        Header: "Method",
        Cell: ({ value }) => <div>{value}</div>,
        width: 100,
      },
      {
        accessor: "lastCommunicationAttemptedAt",
        Header: "Last Sent",
        Cell: ({ value }) => (
          <Popup
            content={<ZonedDateTime value={value} />}
            trigger={value ? <div>{formatDistanceToNow(value)} ago</div> : null}
            inverted
            position='top left'
          ></Popup>
        ),
        minWidth: 120,
      },
      {
        accessor: "subscriptionGroups",
        Header: "Subscription Groups",
        Cell: ({ value, row }) => {
          if (row.original.hasNoSubscriptionGroupPreferences) {
            return <div className='tw-italic'>All auto subscribe groups</div>;
          }
          return <div>{value.map((subscriptionGroup) => subscriptionGroup.name).join(" • ")}</div>;
        },
        width: 300,
        disableSortBy: true,
      },
    ],
    [bulkSelections],
  );

  const { rows, prepareRow, headers, setHiddenColumns, setColumnOrder } = useTable(
    {
      columns,
      data: subscriberContactMethods,
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            sortBy,
          }),
          [state, sortBy],
        );
      },
      manualSortBy: true,
    },
    useSortBy,
    useColumnOrder,
  );

  const totalWidth = headers.reduce((acc, column) => acc + (column.isVisible ? (column.width as number) : 0), 0);

  useEffect(() => {
    if (filters.status === "Enrolled") {
      setHiddenColumns(["origin", "invitedAt", "invitedMethod", "optedOutAt", "optedOutMethod"]);
      setColumnOrder([
        "id",
        "type",
        "identifier",
        "person",
        "createdAt",
        "lastCommunicationAttemptedAt",
        "subscriptionGroups",
      ]);
    }
    if (filters.status === "Invited") {
      setHiddenColumns(["createdAt", "lastCommunicationAttemptedAt", "optedOutAt", "optedOutMethod"]);
      setColumnOrder([
        "id",
        "type",
        "identifier",
        "person",
        "origin",
        "invitedAt",
        "invitedMethod",
        "subscriptionGroups",
      ]);
    }
    if (filters.status === "OptedOut") {
      setHiddenColumns(["origin", "invitedAt", "invitedMethod", "lastCommunicationAttemptedAt"]);
      setColumnOrder([
        "id",
        "type",
        "identifier",
        "person",
        "createdAt",
        "optedOutAt",
        "optedOutMethod",
        "subscriptionGroups",
      ]);
    }
  }, [setHiddenColumns, setColumnOrder, filters.status, bulkSelections]);

  return (
    <>
      <Segment attached='top' className='subText tw-px-[11px] tw-py-2'>
        {isSubscriberContactMethodsLoading ? (
          <span>Loading...</span>
        ) : (
          <span className='tw-flex tw-items-center tw-gap-2'>
            Found {subscriberContactMethodsTotal}{" "}
            {pluralize(subscriberContactMethodsTotal, "subscription", "subscriptions")}
            <Loader inline active={isSubscriberContactMethodsFetching} size='tiny' />
          </span>
        )}
      </Segment>
      <Segment
        attached='bottom'
        loading={false}
        className='notify-subscribers-infinite-table tw-h-full tw-overflow-x-auto tw-overflow-y-hidden tw-p-0'
      >
        <InfiniteLoader
          isRowLoaded={({ index }) => !!rows[index]}
          loadMoreRows={async () => fetchMoreSubscriberContactMethods()}
          rowCount={subscriberContactMethodsTotal}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  headerHeight={45}
                  rowCount={rows.length}
                  rowHeight={45}
                  ref={registerChild}
                  width={Math.max(width - 2, totalWidth)} // Magic 2 to account for border
                  height={height}
                  onRowsRendered={onRowsRendered}
                  rowGetter={({ index }) => {
                    const row = rows[index];
                    prepareRow(row);
                    return row;
                  }}
                  style={{ minWidth: `${Math.max(width - 2, totalWidth)}px` }} // Magic 2 to account for border
                >
                  {headers
                    .filter((column) => column.isVisible)
                    .map((column) => (
                      <Column
                        key={column.id}
                        dataKey={column.id}
                        maxWidth={column.maxWidth}
                        minWidth={column.minWidth}
                        flexGrow={(column as any).flexGrow}
                        flexShrink={(column as any).flexShrink}
                        width={typeof column.width === "number" ? column.width : 150}
                        headerClassName={classNames({
                          "tw-cursor-pointer": column.canSort,
                        })}
                        headerRenderer={() => (
                          <div
                            role='button'
                            tabIndex={-1}
                            onClick={() => {
                              if (column.canSort) {
                                toggleSortBy(column.id, true);
                              }
                            }}
                          >
                            {column.render("Header")}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <Icon name='caret down' />
                              ) : (
                                <Icon name='caret up' />
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                        cellRenderer={({ rowIndex, columnIndex }) => {
                          return rows[rowIndex].cells[columnIndex].render("Cell");
                        }}
                      />
                    ))}
                </Table>
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </Segment>
    </>
  );
}
