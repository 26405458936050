import { fetch as crossFetch } from "cross-fetch";
import type { History } from "history";
import type { Store } from "redux";
import AppSettings from "../config";

export let history: History, store: Store;

const getExcludedKickoutPaths = (site: string) => {
  return [`/${site}/kickout`, `/${site}/login`, `/${site}/public/login`, `/${site}/public/register`];
};
export const kickoutUser = (site: string) => {
  const location = window.location.pathname;
  if (getExcludedKickoutPaths(site).includes(location)) {
    return;
  }
  history.push(`/${site}/kickout?redirect=${location}`);
};

/**
 * Passes the redux store and history object to the network module
 *
 * Note that we cannot just export the store and history objects from index,
 * it results in weird esoteric errors when running tests
 * @param storeRef Reference to the redux store
 * @param historyRef Reference to the history object
 */
export const initialize = (storeRef: Store, historyRef: History) => {
  store = storeRef;
  history = historyRef;
};

export const isInternal = (url?: string) => {
  if (!url) return false;
  return (
    url.includes(AppSettings.GOGOV_BASE_API_URL) ||
    url.includes(AppSettings.GOGOV_WEB_URL) ||
    url.includes(AppSettings.GOGOV_MOBILE_URL) ||
    url.includes(AppSettings.GOGOV_WEB_API_URL) ||
    url.includes(AppSettings.INTERNAL_ADMIN_API_URL)
  );
};

export const isAuthenticatingRequest = (url?: string) => {
  if (!url) return false;
  return url.includes(AppSettings.IDENTITY_API_URL);
};

type NetworkHandler = (site: string, response: Response | null, error: any, ...args: any[]) => void;
const checkNetworkError = (code: number, response: Response, handler: NetworkHandler | undefined, message: string) => {
  if (!handler || response.status !== code) return;
  const site = (store.getState().site as any).site;
  handler(site, response, null);
  throw new Error(message);
};
const checkThrownError = (code: number, error: any, handler: NetworkHandler | undefined, message: string) => {
  if (!handler || !(error?.response?.status === 401 || error?.status === 401)) return;
  const site = (store.getState().site as any).site;
  handler(site, null, error);
  throw new Error(message);
};

/**
 * A wrapper around fetch that logs out the user if an internal API call returns 401
 * @param url Fetch url
 * @param init Fetch options
 * @param {Object} handlers - Handlers for different network errors
 * @param {Function} handlers.onUnauthorized - Function to call when unauthorized, default kickoutUser
 * @param {Function} handlers.onForbidden - Function to call when forbidden, default none
 * @param {Function} handlers.onNotFound - Function to call when not found, default none
 * @returns Fetch response
 */
export async function fetch(
  url: string,
  init?: RequestInit,
  {
    onUnauthorized,
    onForbidden,
    onNotFound,
  }: {
    onUnauthorized?: NetworkHandler;
    onForbidden?: NetworkHandler;
    onNotFound?: NetworkHandler;
  } = {
    onUnauthorized: kickoutUser,
  },
): Promise<Response> {
  try {
    const response = await crossFetch(url, init);

    if (isInternal(url)) {
      checkNetworkError(401, response, onUnauthorized, "Unauthorized");
      checkNetworkError(403, response, onForbidden, "Forbidden");
      checkNetworkError(404, response, onNotFound, "Not Found");
    }

    return response;
  } catch (error: any) {
    if (isInternal(url)) {
      checkThrownError(401, error, onUnauthorized, "Unauthorized");
      checkThrownError(403, error, onForbidden, "Forbidden");
      checkThrownError(404, error, onNotFound, "Not Found");
    }
    throw error;
  }
}

/**
 * A structured way of building API calls that logs out the user if an internal API call returns 401
 * @param query API query
 * @param parseResponse Some function to transform the response, usually a zod parser
 * @param isInternal If this is an internal API call, default true
 * @param {Object} handlers - Handlers for different network errors
 * @param {Function} handlers.onUnauthorized - Function to call when unauthorized, default kickoutUser
 * @param {Function} handlers.onForbidden - Function to call when forbidden, default none
 * @param {Function} handlers.onNotFound - Function to call when not found, default none
 * @returns Parsed response
 */
export async function buildQuery<Response, ParsedResponse>(
  query: Promise<Response>,
  parseResponse: (response: Response) => ParsedResponse,
  isInternal = true, // If this is a call to an internal API
  {
    onUnauthorized,
    onForbidden,
    onNotFound,
  }: {
    onUnauthorized?: NetworkHandler;
    onForbidden?: NetworkHandler;
    onNotFound?: NetworkHandler;
  } = {
    onUnauthorized: kickoutUser,
  },
): Promise<ParsedResponse> {
  try {
    const response = await query;
    return parseResponse(response);
  } catch (error: any) {
    if (isInternal) {
      checkThrownError(401, error, onUnauthorized, "Unauthorized");
      checkThrownError(403, error, onForbidden, "Forbidden");
      checkThrownError(404, error, onNotFound, "Not Found");
    }
    throw error;
  }
}

export const Network = { initialize, fetch, buildQuery };
