import { useState } from "react";

export function useBulkSelect() {
  const [bulkSelections, setBulkSelections] = useState<{ selectAll: boolean; selectExceptions: number[] }>({
    selectAll: false,
    selectExceptions: [],
  });

  const onBulkSelectAll = () => {
    setBulkSelections((prev) => ({
      selectAll: !prev.selectAll,
      selectExceptions: [],
    }));
  };

  const onBulkSelect = (id: number) => {
    setBulkSelections((prev) => {
      return {
        ...prev,
        selectExceptions: prev.selectExceptions.includes(id)
          ? prev.selectExceptions.filter((exception) => exception !== id)
          : [...prev.selectExceptions, id],
      };
    });
  };

  const shouldBulkSelectBeChecked = (id: number) => {
    if (bulkSelections.selectAll) return !bulkSelections.selectExceptions.includes(id);
    return bulkSelections.selectExceptions.includes(id);
  };

  const shouldBulkSelectAllBeIndeterminate = () => {
    return bulkSelections.selectAll && bulkSelections.selectExceptions.length > 0;
  };

  const shouldBulkSelectAllBeChecked = () => {
    return bulkSelections.selectAll;
  };

  return {
    bulkSelections,
    onBulkSelectAll,
    onBulkSelect,
    shouldBulkSelectBeChecked,
    shouldBulkSelectAllBeChecked,
    shouldBulkSelectAllBeIndeterminate,
  };
}
